<template>
	<el-dialog
		v-model="visible"
		width="750px"
		class="container"
		:title="title"
		:before-close="onClose"
		:close-on-click-modal="false"
		append-to-body
		destroy-on-close
	>
		<div v-loading="isLoading">
			<yi-form
				:model="carInfo"
				:rules="rules"
				ref="formRef"
				label-width="120px"
				size="small"
				:form-list="formList"
			>
				<template #carModelName>
					<el-input v-model="carInfo.carModelName" size="default" disabled style="width: 500px" />
				</template>
			</yi-form>
			<yi-form
				:model="model"
				:rules="rules"
				ref="inputRef"
				label-width="120px"
				size="small"
				:form-list="inputForm"
			>
				<template #dateOfAccident>
					<el-date-picker
						style="width: 190px"
						:disabled="disabled"
						size="default"
						v-model="model.dateOfAccident"
						:disabled-date="disabledDate"
					/>
				</template>
				<template #dateOfEntry>
					<el-date-picker
						style="width: 190px"
						:disabled="disabled"
						size="default"
						v-model="model.dateOfEntry"
					/>
				</template>
				<template #dateOfDeparture>
					<el-date-picker
						style="width: 190px"
						:disabled="disabled"
						size="default"
						v-model="model.dateOfDeparture"
					/>
				</template>
			</yi-form>
			<div style="margin-left: 50px">
				<p class="label">事故说明</p>
				<el-input
					type="textarea"
					:disabled="disabled"
					v-model="model.remark"
					maxLength="200"
					style="width: 570px; margin-bottom: 20px"
					:autosize="{ minRows: 4 }"
				/>
			</div>
			<yi-form
				:model="model"
				:rules="rules"
				ref="uploadRef"
				label-width="0px"
				size="small"
				:form-list="uploadForm"
			/>
		</div>

		<template #footer>
			<div class="dialog-footer" v-if="!disabled">
				<el-button type="primary" @click="onSubmit">确定</el-button>
				<el-button @click="onClose">取消</el-button>
			</div>
		</template>
	</el-dialog>
</template>

<script setup>
import dayjs from 'dayjs'
import { defineExpose, ref, inject, defineEmits } from 'vue'
import { useState, useModal, useFetch, useDict } from '@/utils/hooks.js'
import { form, uploadForm as upload, input } from './config'
import { assetIncidental, carSelectList, vehicleDetail, incidentalDetail } from '@/api/vehicle'

const $message = inject('$message')
const emit = defineEmits(['on-refresh'])
const formList = ref(form)
const inputForm = ref(input)
const uploadForm = ref(upload)
const { visible, title, setVisible, setTitle } = useModal()
const [model, setModel] = useState({ sitePics: [], letterOfResponsibilityPics: [] })
const [carInfo, setCarInfo] = useState({})
const [disabled, setDisabled] = useState(false)

const { isLoading, fetchData } = useFetch()
const rules = {
	mileage: [{ pattern: /^[0-9]*$/, message: '车辆里程只能是整数' }],
	maintenanceDays: [{ pattern: /^[0-9]*$/, message: '维修天数只能是整数' }],
	lossAssessmentAmount: [{ pattern: /^[0-9]*$/, message: '定损金额只能是整数' }],
	dateOfDeparture: [{ validator, trigger: 'blur' }],
	insuranceFloatingAmount: [{ validator: amountValidator, trigger: 'blur' }],
	repairBenefitAmount: [{ validator: amountValidator, trigger: 'blur' }],
}
const formRef = ref(null)
const uploadRef = ref(null)
const inputRef = ref(null)

function disabledDate(time) {
	return time.getTime() > new Date(dayjs(new Date()).format('YYYY-MM-DD') + ' 00:00:00').getTime()
}

async function open(type, id) {
	fetchDict()
	fetchList()
	const num = formList.value.find(item => item.prop === 'licensePlateNum')
	num.attrs.disabled = !!id
	inputForm.value.forEach(item => (item.attrs.disabled = type === 'detail'))
	uploadForm.value.forEach(item => (item.attrs.disabled = type === 'detail'))
	if (id) {
		fetchDetail(type, id)
		setTitle('编辑车辆事故库单')
		if (type === 'detail') {
			setTitle('车辆事故库单详情')
			setDisabled(true)
		}
	} else {
		setTitle('新增车辆事故单')
	}
	setVisible(true)
}

function validator(_, value, callback) {
	const { dateOfEntry } = model.value
	if (dayjs(value).isBefore(dayjs(dateOfEntry))) {
		callback(new Error('出厂日期不允许早于进厂日期'))
	} else {
		callback()
	}
}
function amountValidator(_, value, cb) {
	if (!/^(?:[1-9]\d{0,5}|0)(?:\.\d{1,2})?$/.test(value)) {
		cb(new Error('金额只能是6位整数加2位小数'))
	} else {
		cb()
	}
}
async function fetchDetail(type, id) {
	const { err, data } = await fetchData(incidentalDetail, id)
	if (err) return $message.error(err.message)
	setModel(data)
	setCarInfo(data)

	if (type === 'detail') {
		upload.forEach(item => {
			let otherPics = []
			let propPics = []

			if (item.prop === 'letterOfResponsibilityPics') {
				propPics = data.letterOfResponsibilityPics ? data.letterOfResponsibilityPics : []
				otherPics = data.sitePics ? data.sitePics : []
			}

			if (item.prop === 'sitePics') {
				propPics = data.sitePics ? data.sitePics : []
				otherPics = data.letterOfResponsibilityPics ? data.letterOfResponsibilityPics : []
			}

			item.attrs.allImages = [...propPics, ...otherPics]
		})
	} else {
		upload.forEach(item => {
			item.attrs.allImages = []
		})
	}
}
async function fetchDict() {
	const dict = await useDict('liabilityType')
	const liabilityType = inputForm.value.find(item => item.prop === 'liabilityType')
	liabilityType.options = dict.liabilityType.map(item => ({
		label: item.name,
		value: item.code * 1,
	}))
}
async function onNumChange(val) {
	const { err, data } = await fetchData(vehicleDetail, val)
	if (err) $message.error(err.message)
	const { id } = data
	setCarInfo(data)
	setModel(Object.assign(model.value, { carInfoId: id }))
}
async function fetchList() {
	const { err, data } = await fetchData(carSelectList, { statusList: [1, 2, 3, 4, 5, 6, 7, 8] })
	if (err) return $message.error(err.message)
	const licensePlateNum = formList.value[0]
	licensePlateNum.attrs.options = data.map(item => ({
		label: item.licensePlateNum,
		value: item.id,
	}))
	licensePlateNum.on = { change: onNumChange }
}

function onClose() {
	setVisible(false)
	setModel({})
	setCarInfo({})
	setDisabled(false)
}
async function onSubmit() {
	await Promise.all([
		formRef.value.validate(),
		uploadRef.value.validate(),
		inputRef.value.validate(),
	])
	const { dateOfAccident, dateOfDeparture, dateOfEntry } = model.value
	const params = {
		...model.value,
		dateOfAccident: dayjs(dateOfAccident).format('YYYY-MM-DD') + ' 00:00:00',
		dateOfDeparture: dateOfDeparture
			? dayjs(dateOfDeparture).format('YYYY-MM-DD') + ' 23:59:59'
			: null,
		dateOfEntry: dateOfEntry ? dayjs(dateOfEntry).format('YYYY-MM-DD') + ' 23:59:59' : null,
	}
	const { err } = await fetchData(assetIncidental, params)
	if (err) return $message.error(err.message)
	$message.success('操作成功')
	emit('on-refresh')
	onClose()
}

defineExpose({ open })
</script>
<style lang="less">
.el-select-v2__placeholder {
	font-weight: 400 !important;
}
.el-select-dropdown__option-item {
	font-size: 14px !important;
}
</style>
<style lang="less" scoped>
.title {
	border-left: 3px solid #2e3443;
	color: #2e3443;
	padding-left: 4px;
	font-size: 16px;
	font-weight: 500;
}
.maintenance-title {
	.flexible(row, center, space-between);
}
.cursor {
	cursor: pointer;
	color: #02a7f0;
}
.label {
	color: #606266;
	font-size: 12px;
	font-weight: 600;
}
</style>

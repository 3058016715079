export const columns = [
	{
		prop: 'carModelName',
		label: '车型名称',
		width: '300px',
	},
	{
		prop: 'carNum',
		label: '自编号',
		width: '140px',
	},
	{
		prop: 'licensePlateNum',
		label: '车牌号',
		width: '140px',
	},
	{
		prop: 'carColor',
		label: '颜色',
	},
	{
		prop: 'driverName',
		label: '司机姓名',
		width: '120px',
	},
	{
		prop: 'maintainerName',
		label: '维保专员',
		width: '120px',
	},
	{
		prop: 'dateOfAccident',
		label: '事故日期',
		width: '180px',
	},
	{
		prop: 'liabilityType',
		label: '事故责任',
		width: '120px',
	},
	{
		prop: 'lossAssessmentAmount',
		label: '定损金额（元）',
		width: '140px',
	},
	{
		prop: 'mileage',
		label: '公里数（KM）',
		width: '140px',
	},
	{
		prop: 'carInfoCompanyName',
		label: '所属公司',
		width: '240px',
	},
	{
		prop: 'createTime',
		label: '创建时间',
		width: '240px',
	},
	{
		prop: 'operation',
		label: '操作',
		width: '80px',
		fixed: 'right',
	},
]
export const setting = {
	expand: true,
	filters: [
		{
			attr: 'keyword',
			label: '车辆信息',
			type: 'search',
			placeholder: '输入车型名称、车牌、自编号、司机姓名、手机号搜索',
			clearable: true,
			width: 358,
		},
		{
			attr: 'time',
			label: '事故时间',
			type: 'date',
		},
		{
			attr: 'liabilityTypes',
			label: '事故责任',
			type: 'multi-select',
			placeholder: '选择事故责任',
			options: [],
		},
		{
			attr: 'maintainerId',
			label: '维保专员',
			type: 'select',
			placeholder: '选择维保专员',
			options: [],
		},
		{
			attr: 'driverName',
			label: '司机名称',
			type: 'search',
			placeholder: '输入司机名称',
			clearable: true,
		},
		{
			attr: 'carInfoCompanyIds',
			label: '所属公司',
			type: 'multi-select',
			width: 320,
			placeholder: '选择所属公司',
			options: [],
		},
	],
}

export const form = [
	{
		prop: 'licensePlateNum',
		label: '车牌号',
		required: true,
		component: 'el-select-v2',
		attrs: { placeholder: '请选择车牌号', options: [], filterable: true },
	},
	{
		prop: 'carNum',
		label: '自编号',
		attrs: { disabled: true },
		component: 'el-input',
	},
	{
		prop: 'carModelName',
		label: '车辆车型',
		custom: true,
		attrs: { disabled: true },
	},
	{
		prop: 'driverSupportName',
		label: '司服伙伴',
		attrs: { disabled: true },
		component: 'el-input',
	},
	{
		prop: 'maintainerName',
		label: '维保专员',
		attrs: { disabled: true },
		component: 'el-select',
	},
	{
		prop: 'carColor',
		label: '车辆颜色',
		attrs: { disabled: true },
		component: 'el-input',
	},
]
export const input = [
	{
		prop: 'dateOfAccident',
		label: '事故日期',
		required: true,
		custom: true,
		component: 'el-date-picker',
		attrs: { type: 'date', placeholder: '请选择事故日期', 'value-format': 'YYYY-MM-DD' },
	},
	{
		prop: 'liabilityType',
		label: '事故责任',
		required: true,
		component: 'el-select',
		attrs: { placeholder: '请选择事故责任' },
	},
	{
		prop: 'insuranceFloatingAmount',
		label: '保险上浮金额',
		required: true,
		component: 'el-input',
		attrs: { placeholder: '请输入保险上浮金额' },
	},
	{
		prop: 'lossAssessmentAmount',
		label: '定损金额',
		component: 'el-input',
		attrs: { maxLength: 7, placeholder: '请输入定损金额' },
	},
	{
		prop: 'dateOfEntry',
		label: '进厂日期',
		custom: true,
		component: 'el-date-picker',
		attrs: { type: 'date', placeholder: '请选择进厂日期', 'value-format': 'YYYY-MM-DD' },
	},
	{
		prop: 'dateOfDeparture',
		label: '出厂日期',
		custom: true,
		component: 'el-date-picker',
		attrs: { type: 'date', placeholder: '请选择出厂日期', 'value-format': 'YYYY-MM-DD' },
	},
	{
		prop: 'maintenanceDays',
		label: '维修天数',
		component: 'el-input',
		attrs: { maxLength: 7, placeholder: '请输入维修天数' },
	},
	{
		prop: 'mileage',
		label: '车辆里程',
		required: true,
		component: 'el-input',
		attrs: { maxLength: 7, placeholder: '请输入车辆里程' },
	},
	{
		prop: 'repairBenefitAmount',
		label: '事故维修返利',
		component: 'el-input',
		attrs: { placeholder: '请输入事故维修返利' },
	},
]
export const uploadForm = [
	{
		prop: 'sitePics',
		label: '现场照片',
		component: 'yi-file-uploader',
		cWidth: 500,
		attrs: {
			max: 10,
			uploaderSize: 20,
			message: '请上传现场照片',
			productType: 'renrenjia',
			sourceType: 'image',
			accept: '.jpg,.jpeg,.png',
			allImages: [],
		},
	},
	{
		prop: 'letterOfResponsibilityPics',
		label: '定责书照片',
		component: 'yi-file-uploader',
		cWidth: 500,
		attrs: {
			max: 10,
			uploaderSize: 20,
			message: '请上传定责书照片',
			productType: 'renrenjia',
			sourceType: 'image',
			accept: '.jpg,.jpeg,.png',
			allImages: [],
		},
	},
]

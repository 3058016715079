<template>
	<div class="container">
		<yi-list-layout :loading="isLoading">
			<template #title>
				<div class="header-title">事故管理</div>
				<div class="header-button">
					<el-button
						type="success"
						@click="onCreate"
						v-permission="'pc/car-center/mainten-manage/save'"
					>
						<el-icon class="icon-plus"><Plus /></el-icon>
						新增
					</el-button>
					<el-button
						@click="onExport"
						:loading="buttonLoading"
						v-permission="'pc/car-center/mainten-manage/export'"
					>
						导出Excel
					</el-button>
				</div>
			</template>
			<template #filter>
				<yi-filter ref="filterRef" :setting="setting" @change="onChangeFilter" />
			</template>
			<template #table>
				<yi-table
					ref="yiTableRef"
					table-height="100%"
					:columns="columns"
					:data="tableData"
					autoWidth
					:params="{ page }"
				>
					<template #carModelName="{ value, row }">
						<span class="click" @click="onDetail(row)">{{ value }}</span>
					</template>
					<template #liabilityType="scope">
						<span>{{ mapDict('liabilityType', scope.row.liabilityType) }}</span>
					</template>
					<template #dateOfAccident="{ value }">
						<span>{{ dayjs(value).format('YYYY-MM-DD') }}</span>
					</template>
					<template #operation="{ row }">
						<el-button
							link
							type="primary"
							@click="onEdit(row)"
							v-permission="'pc/car-center/mainten-manage/edit'"
						>
							编辑
						</el-button>
					</template>
				</yi-table>
			</template>
			<template #footer>
				<yi-pagination
					:current-page="page.current"
					:page-size="page.size"
					:total-count="totalCount"
					@page-change="onPageChange"
					@size-change="onSizeChange"
				/>
			</template>
		</yi-list-layout>
		<incidental-form ref="formRef" @on-refresh="onRefresh" />
	</div>
</template>

<script name="IncidentManagement">
import dayjs from 'dayjs'
import { ref, defineComponent, inject, onMounted, getCurrentInstance, onActivated } from 'vue'
import { setting as s, columns } from './config'
import { getStore } from '@/utils/store'
import { useRoute } from 'vue-router'
import { InitTable, useFetch, useState, useDict } from '@/utils/hooks.js'
import { incidentalList, companySelect, exportAccident, maintenanceSelect } from '@/api/vehicle.js'
import { handleExport } from '@/utils/util'
import IncidentalForm from './form'

export default defineComponent({
	name: 'IncidentManagement',
	components: {
		IncidentalForm,
	},
	setup() {
		const $message = inject('$message')
		const formRef = ref(null)
		const setting = ref(s)
		const userInfo = getStore({ name: 'userInfo' })
		const [params, setParams] = useState({
			page: { current: 1, size: 20 },
			accidentStTime: dayjs().subtract(1, 'month').format('YYYY-MM-DD') + ' 00:00:00',
			accidentEndTime: dayjs().format('YYYY-MM-DD') + ' 23:59:59',
		})
		const service = incidentalList
		const [buttonLoading, setLoading] = useState(false)
		const [dict, setDict] = useState({})
		const { fetchData } = useFetch()
		const table = new InitTable(service, params)
		const methods = {
			onChangeFilter(params) {
				const { time, ...rest } = params
				const data = { ...rest }
				if (Array.isArray(time) && time.length) {
					const [start, end] = time
					data.accidentStTime = start
					data.accidentEndTime = end
				}
				setParams(data)
				table.onChangeFilter(data)
			},
			onRefresh() {
				table.fetchList()
			},
			onCreate() {
				formRef.value.open('create')
			},
			onEdit(row) {
				formRef.value.open('update', row.id)
			},
			onDetail(row) {
				formRef.value.open('detail', row.id)
			},
			mapDict(key, value) {
				const e = dict.value[key]
				if (!e) return '--'
				const target = e.find(item => item.code == value)
				return target ? target.name : '--'
			},
			async onExport() {
				setLoading(true)
				try {
					const res = await exportAccident(table.params)
					if (res) handleExport(res, '事故管理')
				} catch (err) {
					$message.error(err.message)
				} finally {
					setLoading(false)
				}
			},
		}
		async function fetchSelect() {
			const { err, data } = await fetchData(companySelect, { isPermission: true })
			if (err) $message.error(err.message)
			const company = setting.value.filters.find(item => item.attr === 'carInfoCompanyIds')
			company.options = data.map(item => ({ name: item.companyName, code: item.companyId }))
		}
		async function fetchMaintenance() {
			const { companyId } = userInfo
			const list = companyId ? companyId.split(',') : []
			const { err, data } = await fetchData(maintenanceSelect, 'Mainten', list, { isAll: true })
			if (err) $message.error(err.message)
			const maintainer = setting.value.filters.find(item => item.attr === 'maintainerId')
			maintainer.options = data.map(item => ({ name: item.realName, code: item.id }))
		}
		async function fetchDict() {
			const dict = await useDict('liabilityType')
			if (dict) setDict(dict)
			const liabilityType = setting.value.filters.find(item => item.attr === 'liabilityTypes')
			liabilityType.options = dict.liabilityType.map(item => ({
				name: item.name,
				code: item.code * 1,
			}))
		}

		onMounted(() => {
			init()
		})
		function init() {
			const ins = getCurrentInstance()
			const data = params.value
			data.time = [data.accidentStTime, data.accidentEndTime]
			ins.refs.filterRef && ins.refs.filterRef.manualSetParams(data)
			fetchSelect()
			fetchMaintenance()
			fetchDict()

			const route = useRoute()
			const query = route.query
			if (Object.keys(query).length > 0) {
				params.value.keyword = query.keyword || ''
				params.value.carInfoCompanyIds = query?.companyIds?.split(',') || []
				const [start, end] = query?.date?.split(',') || []
				params.value.accidentStTime = (start && `${start} 00:00:00`) || null
				params.value.accidentEndTime = (end && `${end} 23:59:59`) || null
				params.value.liabilityTypes =
					(query.status && query.status.split(',').map(item => Number(item))) || null
				params.value.time =
					(start && [`${start} 00:00:00`, end ? `${end} 23:59:59` : `${start} 00:00:00`]) || null
				const ins = getCurrentInstance()
				ins.refs.filterRef && ins.refs.filterRef.manualSetParams(params.value)
			} else {
				table.fetchList()
			}
		}
		onActivated(() => {
			init()
		})

		return {
			setting,
			columns,
			formRef,
			buttonLoading,
			dayjs,
			...table.res,
			...methods,
		}
	},
})
</script>

<style lang="less" scoped>
.container {
	width: 100%;
	height: 100%;
	overflow: hidden;
	.header-title {
		height: 54px;
		line-height: 54px;
		overflow: hidden;
		float: left;
	}
	.header-button {
		margin: 11px 0;
		float: right;
	}
	.click {
		cursor: pointer;
		color: #409eff;
	}
}
</style>
